<template>
  <div>
  <v-container fluid class="ma-0 pa-0">
      <v-row class="ma-0 pa-0">
        <v-col :align="getPosition()" class="ma-0 pa-0" >
          <div class="text--caption">{{ GetAgo() }}</div>

          <v-card v-if="showFirst()" style="width:75%;" class="ma-2" :color="getColor()">
           <v-card-title class="subtitle-2"  style="display:flex;">          
            <v-avatar >
            <AvatarView :sender="message.sender" width="300" label="false"></AvatarView>
          </v-avatar>
          
          <span style="margin-left:10px; flex:10; text-align:left;">{{ message.messageDeets.s }}</span>

          <span style=" flex-grow:1; text-align:right;" v-if="message.messageDeets.x!=0"><v-icon color="green" @click="ShowPromo(message)">{{ GetIcon(message) }}</v-icon></span>
          <div class="endMessage" v-if="this.messageIndex == this.messageCount-1"></div>

        </v-card-title>

      </v-card> 
        </v-col>

      </v-row>
    </v-container>

    <v-dialog v-model="PromoVisible" fullscreen>
      <v-card>
        <v-toolbar
          dark
          color="black"
        >
          <v-btn
            icon
            dark
            @click="PromoVisible = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title><v-img width="100" src="../../src/assets/engagr-sm.png"></v-img></v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container>
          <v-row><v-col></v-col></v-row>
          <v-row>
            <v-col>
              <v-sheet
                elevation="10">
                
                <MessageReward
                  :addr="addr"
                  :message="message"  
                  :tracking="tracking"
                  :sessionGuid="sessionGuid"
                  :sessionTxID="sessionTxID"
                  :messageID="messageID"
                >
                </MessageReward>

              </v-sheet>

            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    </div>
</template>

<script>
//import axios from "axios";
//import NFTView from "./NFT-View.vue";
import AvatarView from "./Avatar-View.vue";
import MessageReward from "./Message-Reward.vue";
import * as moment from 'moment'


import axios from "axios";


export default {
  name: "MessagePart",
  props: ["addr","buddyID","sessionGuid","sessionTxID","message", "tracking", "messageID","showAll","displayAll","visibleView","messageIndex","messageCount"],
  components:{
    MessageReward,
    AvatarView,
  },
  data: () => {
    return {
      PromoVisible: false,
      showBody: false,
      ActiveMessage: null,
      ReadStart: null,
      
    }
  },
   mounted(){
    if(this.messageIndex == this.messageCount-1){
      this.$vuetify.goTo(".endMessage")
    }
  },  
  watch: {
    // whenever addr changes, this function will run
    addr: function(newAddr, oldAddr) {
      if(newAddr != oldAddr){
        console.log("changeThing")
      }
    },
    PromoVisible:function(newVal, oldVal) {
      //it has been closed
      if(newVal == true){
        this.ReadStart = Date.now();
      }

      if(oldVal == true){
        var rt = Date.now() - this.ReadStart;
        this.$emit("PromoClosed", this.ActiveMessage, rt)
      }
    }
  },
  methods: {
    showFirst(){
      return ((this.messageIndex==0 && this.location == "before" )||
              (this.messageIndex==(this.messageCount-1) && this.location == "after" ) ||
              this.showAll==true)
    },
    showLast(){

    },
    showMore(messageIndex){
 
      //logic to show in the first place
      if(this.showAll == true){
        return false
      } else if(messageIndex==1 && this.messageCount>3){
        return true;
      } else {
        return false
      }
    },
    toggleBody(){
      if(this.showBody==true) this.showBody = false;
      else this.showBody = true;
    },
    toggleAll(){
      this.$emit("showAll")
    },
    getColor(){
      if(this.addr != this.message.sender){
        return "#BBDEFB"
      } else {
        return "#EEEEEE"
      }
    },
    getPosition(){
      if(!this.message){
        console.log("NO MESSAGE")
        console.log(this)
      }

      if(this.addr == this.message.sender){
        return "right"
      } else {
        return "left"
      }
    },
    GetIcon(){
      if(this.tracking.x > 0)
        return "";
      
      if(this.tracking != null &&
         this.tracking.r == 0){
        return "mdi-gift-outline"
      } else {
        return "mdi-gift-open-outline"
      }    
    },
    GetAgo(){
      return moment.unix(this.message.sendDate).fromNow()
    },
    getCount(){
      return this.messageCount - (this.messageIndex + 1)
    },
    ClickCall(){
      //if(this.to != this.addr){
        var headers = {
              'sessionGuID': this.sessionGuid,
              'sessionTxID': this.sessionTxID,
              'walletID': this.addr
          }

        var message_payload = {
              "u": this.addr,
              "d": {
                "i":this.message.messageDeets.c
              },
              "t": "click"
            }
        if( this.visibleView == "inbox"){
          axios.post(".netlify/functions/LogAction",message_payload, { headers: headers} )
          .then(async function (response) {
            console.log("LOGGEDCLICK: " + response.data)
          })
          .catch(function (error) {
                console.log(error);
          });
          window.open(this.message.messageDeets.c )
        }
    },
    ShowPromo(m){
      this.ActiveMessage = m;
      this.$emit("PromoShowed", m)
      this.PromoVisible = true;
    }

  }
    
    
};
</script>

