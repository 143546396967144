<template>
    <v-sheet v-if="ActiveMessages.length != 0">
        <MessagePart
            v-for="Message, itemObjKey in ActiveMessages" 
            :message="Message"
            :tracking="GetTracking(Message)"
            :messageIndex="itemObjKey"
            :messageCount="ActiveMessages.length"
            :location="location"   
            :key="Message.messageID"
            :addr="addr"
            :buddyID="buddyID"
            :sessionGuid="sessionGuid"
            :sessionTxID="sessionTxID"
            :showAll="showAll"
            :displayAll="displayAll"
            :visibleView="visibleView"
            @showAll="showAllMessages"     
            @PromoShowed="ShowPromo"
            @PromoClosed="PromoClosed"
        ></MessagePart>

    </v-sheet>
</template>

<script>
import axios from "axios";
import * as moment from 'moment'

import MessagePart from "./Message-Part.vue";


export default {
  props: ["addr","buddyID","sessionGuid","sessionTxID","location", "messageID","selectedMessage","visibleView","displayAll","renderCount","previewMessage" ],
  components:{
    MessagePart,
  },
  data: () => {
    return {
      avatarURL: "",
      intervalWatch: null,
      ActiveMessages: [],
      showAll: true,
      TrackingThread: [],
      MessageThread: [], //[{"sendDate":1674444421,"messageDeets":{"s":"RE:Hello berb enthusiast!","b":"\u003cp\u003eI figured out my bug, I am stupid is the issue\u003c/p\u003e","t":"LMDGOUWPZXLTCQUV3YRLJ6BV3RFNIWGZVENV7HDL4I5WKRKLIJK2PSHIBA","m":"cta","c":"https://twitter.com/OspreyRoost/status/1617362398418984960","a":"","x":0.001},"sender":"HYPPC23EEYBQJH464IDZTERWZDLOLZCE66MPWBLN3P53H4WYIY62ZWNUJM","assetID":"5FCYDGT7W3437ISTT74MXJ3SLIPF4Y5KFZ5CYVLRBAEWSF6V4H2Q","avatarURL":"","domain":""},{"sendDate":1674254476,"messageDeets":{"s":"RE:Hello berb enthusiast!","b":"\u003cp\u003eThank you, that was awesome\u003c/p\u003e","t":"LMDGOUWPZXLTCQUV3YRLJ6BV3RFNIWGZVENV7HDL4I5WKRKLIJK2PSHIBA","m":"cta","c":"https://algoxnft.com/asset/847770124","a":"847770124","x":1},"sender":"HYPPC23EEYBQJH464IDZTERWZDLOLZCE66MPWBLN3P53H4WYIY62ZWNUJM","assetID":"UGT6BDHABIGDQKJJQMYVX6RGBMKEMW7J2SYBBS5GTE5U6UQR7R2Q","avatarURL":"","domain":""},{"sendDate":1674253176,"messageDeets":{"s":"Hello berb enthusiast!","b":"\u003cp\u003eI hear you like beRbs. \u003c/p\u003e\u003cp\u003eMay I interest you in a potato?\u003c/p\u003e","t":"HYPPC23EEYBQJH464IDZTERWZDLOLZCE66MPWBLN3P53H4WYIY62ZWNUJM","m":"cta","c":"","a":"","x":1},"sender":"LMDGOUWPZXLTCQUV3YRLJ6BV3RFNIWGZVENV7HDL4I5WKRKLIJK2PSHIBA","assetID":"BN3RD2B4ROATZE6QI3JMPODIHDVDS5ZVSRNLVOEPLSPKKPIP74FQ","avatarURL":"","domain":""},{"sendDate":1674242152,"messageDeets":{"s":"Here is a test","b":"\u003cp\u003eGive it a look\u003c/p\u003e","t":"LMDGOUWPZXLTCQUV3YRLJ6BV3RFNIWGZVENV7HDL4I5WKRKLIJK2PSHIBA","m":"cta","c":"https://www.nftexplorer.app/asset/765786809","a":"765786809","x":1},"sender":"HYPPC23EEYBQJH464IDZTERWZDLOLZCE66MPWBLN3P53H4WYIY62ZWNUJM","assetID":"5GLYHD6BJP5EHOOLAAMXV4RQU5N3H5TL6WY4BUB27NGID3EQUYMQ","avatarURL":"","domain":""}]
    }
  },
  async mounted(){
    console.log("Mounted")
    await this.LoadBits(this);
    console.log("Start Intevrval - Mount")

//    this.CutArray();
  },  
  watch: {
    previewMessage: function(oldVal, newVal){
        if(oldVal == true){
            this.intervalWatch = window.setInterval(this.UpdateThread, 20 * 1000, this )
        }
        if(newVal==true){
            if(this.intervalWatch)
                window.clearInterval(this.intervalWatch)    
        }
    },
    ActiveMessages: async function( oldM, newM ){
        console.log(oldM)
        console.log(newM)
        await this.LoadBits(this);
    },
    // whenever addr changes, this function will run
    messageID: function(newMessage, oldMessage) {
        console.log("MessageView: " + this.addr + " >> " + this.buddyID)
        var threadObj = {addr:this.addr, threadBuddy:this.buddyID}

        if(newMessage != oldMessage){
        this.$store.dispatch("getThread", threadObj)


        this.MessageThread = [];
        this.ActiveMessages = [];
        this.LoadBits(this)


        console.log("Start Intevrval")
        this.intervalWatch = window.setInterval(this.UpdateThread, 10 * 1000, this )
      }
    }
  },
  methods: {
        async LoadBits(){
            console.log("MV: BUDDY")
            console.log(this.buddyID)
            var threadObj = {addr:this.addr, threadBuddy:this.buddyID, loadAll: true}

            var retVal = await this.$store.dispatch("updateThread", threadObj)
            this.MessageThread = retVal.messages;
            this.TrackingThread = retVal.tracking;

            this.CutArray();

        },

        async UpdateThread(me){
            var threadObj = {addr:me.addr, threadBuddy:me.buddyID, loadAll:false}
            var retVal = await this.$store.dispatch("updateThread", threadObj)

            me.MessageThread = retVal.messages;
            me.TrackingThread = retVal.tracking;

            this.CutArray();

        },
        CutArray(){
            var messageIndex = -1;

            for(var i=0; i<this.MessageThread.length; i++){

                if(this.MessageThread[i].assetID == this.messageID){
                    messageIndex = i;
                    //break;
                }
            }

            if(this.location == "before")
                this.ActiveMessages = this.MessageThread.slice(0, (messageIndex))
            else if(this.location == "after")
                this.ActiveMessages = this.MessageThread.slice(messageIndex+1)
            else if(this.location == "showAll")
                this.ActiveMessages = this.MessageThread

        },

        async GetURL(m){
            var url = "https://api.nf.domains/nfd/address?address="+ m.sender +"&view=thumbnail&limit=1"

            await axios.get(url).then((response) => {  


                if( response.data.properties.verified){
                    var avatar = response.data.properties.verified.avatar
                    var suffix = avatar.substr(7)
                    return  "https://ipfs.algonode.xyz/ipfs/" + suffix;
                }
                else if(response.data.properties.userDefined){
                    return  response.data.properties.userDefined.avatar;
                }
            });


            return "https://i.pravatar.cc/64"
        },
        GetLeft(m){
            if(this.isMobile()){
                return false
            } else {
                return (this.addr == m.sender)
            }

        },        
        GetRight(m){
            if(this.isMobile()){
                return true
            } else {
                return (this.addr != m.sender)
            }
        },
        GetColor(m){
            if (this.messageID == m.assetID){
                return "";
            }
            else{
                return "#ECEFF1"
            }
        },
        ClickCall(m){
            window.open(m.messageDeets.c )
        },
        FormatDate: function (val) {
            return moment.unix(val.sendDate).fromNow()
        },
        
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        showAllMessages(){
            this.showAll = true;
        },
        GetTracking(m){
            if(m.assetID in this.TrackingThread){
                return this.TrackingThread[m.assetID]
            } else {
                return {
                    r: 0, l: 0, c: 0, x:0, rt:0
                };
            }
        },
        ShowPromo(m){
            this.$emit("PromoShowed", m)
        },
        PromoClosed(m, rt){
            this.$emit("PromoClosed", m, rt)
        }


    }
};
</script>

