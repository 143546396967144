import Vue from 'vue'
import App from './App.vue'
import vuetify from "./plugins/vuetify";
import store from "./plugins/vuex";
import VueTour from 'vue-tour'
//import Vuelidate from 'vuelidate'


//import router from './router'

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)
//Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

//Vue.prototype.$vue = vm;

//console.log(vm)

/*
new Vue({
  el: "#app",
//  router,
  store: store,
  vuetify: vuetify,
  render: h => h(App),
}).$mount('#app')
*/