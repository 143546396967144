<template>  
  
  <v-app>

    <v-navigation-drawer 
      v-model="drawer" temporary absolute>
      <NavigationDrawer
        :addr="addr"
        :ForceRefresh="ForceRefresh"
        :unread="unread"
        v-on:Navigate="Navigate"
        @ForceShow="ShowForceNotification"
        ></NavigationDrawer>
    </v-navigation-drawer>


    <v-app-bar app color="black">
      <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title><v-img width="100" src="../src/assets/engagr-sm.png"></v-img></v-toolbar-title>
      <v-spacer></v-spacer> 
      <v-icon color="white" @click="disconnect">mdi-logout</v-icon>
    </v-app-bar>

    <v-main>
      <v-system-bar
      dark
      color="#333333"
      height="30"
    >My Wallet</v-system-bar>

      <v-container fluid style="background-color:#dddddd; height:100%">
        <v-row>
          <v-col fluid class="pa-0 ma-0" >
            <v-card
              class="mx-auto"
            >

            <v-snackbar
              v-model="notice"
              :color="noticeColor"
              dismissible
              prominent
              timeout="4000"
              right
                          
              transition="scale-transition"
              align="right"
            >
              {{noticeText}}
            </v-snackbar>
      <v-speed-dial>
        <template v-slot:activator>
          <v-btn
              fixed
              fab
              bottom
              right
              dark
              @click="showCreate"
              class="green lighten-1"
            >
              <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-speed-dial> 


            <!-- v-btn
                absolute
                fab
                bottom
                right
                dark
                @click="showCreate"
                class="green lighten-1"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn-->
        
              <InboxList
                v-if="inbox"
                :Messages="Messages"
                :Tracking="Tracking"
                MessageListTitle="Inbox"
                :ForceRefresh="ForceRefresh"
                :addr="addr"
                @Refresh="Refresh"
                v-on:MessageSelected="MessageSelected"
                @showCreate="showCreate"
              ></InboxList>

              <!-- OutboxList
                v-if="outbox"
                :Messages="OutboxMessages"
                @Refresh="Refresh"
                :Tracking="OutboxTracking"
                MessageListTitle="Sent Messages"
                :ForceRefresh="ForceRefresh"
                v-on:MessageSelected="MessageSelected"
                @showCreate="showCreate"
              ></OutboxList  -->

              <PaymentsList
                v-if="payments"
                @Refresh="Refresh"
                :sessionGuid="sessionGuid"
                :sessionTxID="sessionTxID"
                :walletID="addr"
              ></PaymentsList>

              <RewardsList
                v-if="rewards"
                @Refresh="Refresh"
                :sessionGuid="sessionGuid"
                :sessionTxID="sessionTxID"
                :walletID="addr"
              ></RewardsList>

              <!--v-btn @click="getThing" 
                    fab
                    absolute
                    bottom
                    right
                    large
                    class="text--white"
                    color="light-red lighten-3"
                    ><v-icon>mdi-home</v-icon></v-btn-->


            </v-card>

            </v-col>
        </v-row>
      </v-container>


      <v-bottom-sheet
         v-model="compose"
         inset
         scrollable
         max-width="1024"
      >
    
      <MessageForm
        FormPurpose="Compose Message"
        :addr="addr"
        :sessionGuid="sessionGuid"
        :sessionTxID="sessionTxID"
        to=""
        subject=""
        @send="send"
        @closeWindow="closeWindow"
      ></MessageForm>

  </v-bottom-sheet>

    </v-main>

    <v-footer
    color="blue darken-1"
    padless
  >
    <v-row
      justify="center"
      no-gutters
    >

      <v-col
        class="grey darken-2 body-1 text-center white--text"
        cols="12"
      >
      <v-btn
        icon
        color="white"
        @click="ftux=true"
      >
            First Time User?
          <v-icon>mdi-menu-up</v-icon>
      </v-btn>
      </v-col>
    </v-row>
  </v-footer>






    <v-dialog 
      v-model="connected" 
      persistent 
      max-width="600"
      color="#ffffff">

          <v-card outlined>
            <v-card-title class="black--text">Let's get started with</v-card-title>
            <v-img width="600" src="../src/assets/engagr-splash.png"></v-img>
            <v-card-text style='text-align:left;'><br/>Send a message to another wallet in Algorand. Will it be read? No idea, with Engagr, you can place a bounty for the reader where they get paid to read. To get started, click one of the wallet buttons below. <br/><br/>
              <div><strong>Note</strong>, for your security, we are going to have to do this a second time. Why? We use the block chain to ensure your safety and security.</div></v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <BigWallet
                v-on:DeflyConnect="DeflyConnect"
                v-on:PeraConnect="PeraConnect"

                :addr="this.addr"
                :showWallet="this.showWallet"
              />
            </v-card-actions>
          </v-card>
      </v-dialog>



    <v-dialog
         v-model="previewMessage"
         align="center"
         max-width="1024px"
         height="90%"
         ref="messageViewDialog"
         scrollable
         fullscreen
      >

      <MessageThread
        :addr="addr"
        :buddyID="buddyID"
        :sessionGuid="sessionGuid"
        :sessionTxID="sessionTxID"
        :messageID="id"
        :visibleView="visibleView"
        :selectedMessage="openMessage"
        :tracking="Tracking"
        :renderCount="renderCount"
        :previewMessage="previewMessage"
        @send="send"
        @closeWindow="closePreview"
        @PromoShowed="PromoShowed"
        @PromoClosed="PromoClosed"
        >
      </MessageThread>
    </v-dialog>
  
    <v-dialog
      v-model="ftux"
      color="white"
      max-width="1024"
      transition="dialog-bottom-transition"
      >

      <FTUX @closeFTUX="closeFTUX"></FTUX>

    </v-dialog>

    <v-dialog 
      v-model="reminders"
      max-width="1024"
      transition="dialog-bottom-transition">
      <NotificationSetup
        :addr="addr"
        :sessionGuid="sessionGuid"
        :sessionTxID="sessionTxID"
        :pushEnabled="pushEnabled"
        @ForceShow="ShowForceNotification"
      ></NotificationSetup>
    </v-dialog>

    <v-overlay 
      v-model="busy"
      color="#000000"
      opacity=".5">
      
      <v-img height="300" 
            class="icon-spinner" 
            src="../src/assets/hokr-icon.png" >
      </v-img>
    </v-overlay>

    

  </v-app>
</template>

<script>
//import Vuetify from 'vuetify'
import InboxList from "./components/Inbox-List.vue";
//import OutboxList from "./components/Outbox-List.vue";
import RewardsList from "./components/Rewards-List.vue";
import PaymentsList from "./components/Payments-List.vue";
import MessageForm from "./components/Message-Form.vue";
import MessageThread from "./components/Message-Thread.vue";
import FTUX from "./components/FTUX.vue";
import WalletBroker from "./plugins/wallets/WalletBroker.js";
import NavigationDrawer from "./components/Navigation-Drawer.vue";
import NotificationSetup from "./components/Notification-Setup.vue";
import BigWallet from "./components/BigWallet.vue";
import Cookies from 'js-cookie'
import axios from "axios";
import { Buffer } from "buffer";
//import * as moment from 'moment'


window.Buffer = Buffer;

//const stdlib = loadStdlib("ALGO");
const algosdk = require('algosdk');
//const OneSignal = require('onesignal-node');

const algodToken = "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa";
//const indexServer = "https://mainnet-idx.algonode.cloud";
const algodServer = "https://mainnet-api.algonode.cloud";
const algodPort = 443;

let algodClient = new algosdk.Algodv2(algodToken, algodServer, algodPort);

export default {
  name: "App",
  components: {
    InboxList,
    //OutboxList,
    RewardsList,
    PaymentsList,
    FTUX,
    BigWallet,
    NavigationDrawer,
    NotificationSetup,
    MessageForm,
    MessageThread,
    
  },
  data: () => { 
    return {
      connected: true,
      busy: true,
      notice: false,
      noticeText: "",
      noticeColor: "green",
//      beforeMessage: true,
//      afterMessage: true,
      ForceRefresh:0,
      
      inbox:true,
//      outbox: false,
      rewards: false,
      payments: false,

      algodClient: null, /// new algosdk.Algodv2(algodToken, algodServer, algodPort),
      //acc: undefined,
      //acc2: undefined,
      addr: undefined,
      sessionGuid: "",
      sessionTxID: null,
      //myAlgoConnect: null,
      NFTCheck: "",
      //AccountCheck:"",
      drawer: false,
      showWallet: true,
      dialog: false,
      //reply: true,
      compose: false,
      ftux: false,
      //ownerProfile: { name:"engagr.xyz",
      //                properties:{ userDefined:{avatar:""} } },
      openMessage: null,
      asaid: 0,
      //showAsa: false,
      //showCTA: false,
      from:"",   
      to:"", 
      id: "",  
      send_address:"",   
      send_date:"",   
      subject: "",
      hero_url: "",
      hero_height: "",
      content: "",
      contentCount: 0,
      callToAction: "",
      buddyID: "", 
      wallet: null,
      
      previewMessage: false,
      //nft: null,
      validated_id: false,
      validated_account: false,
      //rules: [],
      accountResults: "",
      accountColor: "green",
      asaResults: "",
      asaColor: "green",
      //bounty: 0.0,
      startRead: null,
      finishRead: null,
      visibleView: "inbox",
      
      lastInboxPull: null,
      reminders: false,
      //lastOutboxPull: null,

      Messages:[],
      //OutboxMessages:[],
      Tracking:[],
      //OutboxTracking: [],

      walletType: "",
      peraWallet: null,
      polling: null,
      unread: 0,
      renderCount: 0,
    };
  },
  async mounted(){  
    if( Cookies.get("sessionTxID") && 
        Cookies.get("sessionGuid")) {

        this.walletType = Cookies.get("walletType");


        if(this.walletType==null ||
           this.walletType === 'undefined'){
            console.log("No wallet type is set")
            console.log("disconnecting")
          this.disconnect()
        } else {
          WalletBroker.reconnect(this);

          this.addr = Cookies.get("walletID");

          this.sessionTxID = Cookies.get("sessionTxID");
          this.sessionGuid = Cookies.get("sessionGuid");
          this.walletType = Cookies.get("walletType");

          var d = await this.$store.dispatch("GetNotificationConfig", {})

          //show reminders
          if(!(d.length > 0 || window.OneSignal.isPushNotificationsEnabled())){
            this.reminders = true;
          }

          /*
          window.OneSignal.Show

          window.OneSignal.getSubscription()
          .then(function(result) {
            console.log(result) // "Some User token"
          })

          window.OneSignal.getIdsAvailable()
          .then(function(result) {
            console.log(result) // "Some User token"
          })

          window.OneSignal.getNotificationPermission()
          .then(function(result) {
            console.log(result) // "Some User token"
          })

          window.OneSignal.getUserId()
          .then(function(result) {
            console.log(result) // "Some User token"
          })

          window.OneSignal.setExternalUserId(this.addr)
          */

          this.$store.commit("SET_SESSION_GUID",  Cookies.get("sessionGuid"));
          this.$store.commit("SET_SESSION_TXID",  Cookies.get("sessionTxID"));
          this.$store.commit("SET_WALLETID",      Cookies.get("walletID"));
          this.$store.commit("SET_WALLETTYPE",    Cookies.get("walletType"));

          this.connected = false;
          this.busy = false;

          this.Navigate("inbox")

        } 
    } else {
      //this.disconnect();
    }
  },
  watch:{

    previewMessage() {

    }
  },
  methods: {
    async disconnect(){

      this.connected      = true;
      this.busy           = true;

      this.Messages       =[] 
      this.Tracking       =[] 

      Cookies.set("walletType", "");
      Cookies.set("walletID", "");
      Cookies.set("sessionTxID", "");
      Cookies.set("sessionGuid", "");

/*      
      if(this.wallet)
      {
        await this.wallet.disconnect();
      }
      */
      this.ShowNotification("You have been logged out.", "green")
    },
    closeFTUX(){
      this.ftux = false;
    },
    Refresh(){
      switch(this.visibleView){
        case "inbox":
          this.lastInboxPull = null;
          this.Messages = [];
          this.Tracking = {};
          this.GetInbox(this, 0);
          break;
 
      }

    },
    async DeflyConnect(){
      console.log("DEFLY CONNECT")
      this.wallet = await WalletBroker.connect("Defly", this);

      window.OneSignal.setExternalUserId(this.addr)
    },
    async PeraConnect() {
      this.wallet = await WalletBroker.connect("Pera", this);

      window.OneSignal.setExternalUserId(this.addr)

      this.showWallet = false;
      this.connected = false;
      this.busy = false;

    },

    async getHero(){
      if(this.asaid == 0){
        this.hero_url = ""
        this.validated_id = false;
        this.asaResults = ""
      
      } else {
        try{
          var me = this;

          var message_payload = {
          "asaid": this.asaid
          }

          var headers = {
                'sessionGuID': this.sessionGuid,
                'sessionTxID': this.sessionTxID,
                'walletID': this.addr,
                'view': "nftsrc"
            }

            axios.post( ".netlify/functions/Message",message_payload, { headers: headers} )
            .then(async function (response) {
              console.log(response.data)
              var suffix = response.data.substr(7)
              me.hero_url = "https://ipfs.algonode.xyz/ipfs/" + suffix;
              console.log(me.hero_url)
              me.validated_id = true;
              //me.hero_height = "100%";
              me.asaResults = "valid";
              me.NFTCheck = "mdi-checkbox-marked";
            })


        }
        catch(exception){
          console.log(exception)
          this.validated_id = false;
          this.asaResults = "Invalid ASA ID"  
          this.NFTCheck = ""
          //this.hero_height=100
          this.hero_url = ""
        }
      }
    }, 
    pushEnabled(){
      return window.OneSignal.isPushNotificationsEnabled()
    },
    send(send){
      console.log("APP SEND")
      console.log(send)
    
      var message_payload = {
        "u": this.addr,
        "d": send
      }

      var headers = {
            'sessionGuID': this.sessionGuid,
            'sessionTxID': this.sessionTxID,
            'walletID': this.addr,
            'view': "send"
        }

      let my = this;

      axios.post(".netlify/functions/Message",message_payload, { headers: headers} )
      .then(async function (response) {
        var txList = [];

        for(var tx in response.data){
          txList[tx] = algosdk.decodeUnsignedTransaction(Buffer.from(response.data[tx], "base64"))          
        }

//        var txID = await my.SignTransaction(txList);
        console.log(my.wallet)


        var txID = await my.wallet.sign(txList)
        let confirmedTxn = await algosdk.waitForConfirmation(algodClient, txID, 4);
        console.log(confirmedTxn)
        
        //set the send id
        send.i = txID;
        message_payload.t = "send"

        //send notification message to this.sendAddress
        my.SendNotification(send)

        axios.post(".netlify/functions/LogAction",message_payload, { headers: headers} )
        .then(async function (response) {
            console.log("LOGGEDSEND: " + response.data)
        })
        .catch(function (error) {
          console.log(error);
        });

        my.ShowNotification("Message sent", "green")

        //add message to Messages List
        var m = {
          sendDate: Math.round(Date.now()/1000),
          sender: my.addr,
          messageDeets: send,
          assetID: Date.now()//txID
        }
        var t = {
          l: 0,
          r: 0,
          rt: 0,
          clicks: 0,
          copies: 0
        }

        var payload = {
          message: m,
          tracking: t,
          buddy: send.t,
          addr: my.addr,
        }
        
        my.$store.commit("ADD_ONE_MESSAGE", payload)
        my.GetInbox(my, 0);
        
        //this.renderCount++;

      })
      .catch(function (error) {
        console.log(error);
        my.ShowNotification("There was an error sending your message: "  + error, "red")
      });
    },
    closeWindow(){
      this.compose = false
    },
    closePreview(){
      this.previewMessage = false
    },
    /*
    async SignTransaction(txList){
      return this.wallet.sign(txList)
    },
*/ 
    async ListMessages() {
      this.busy=true;
      if(!this.polling){
       // this.polling = window.setInterval( this.GetInbox, 30000, this )
      }

      this.GetInbox(this, 0);

    },
    async GetInbox(main, count){
      var getAll = false;

      if(main.lastInboxPull == null)
      {
        getAll = true;
        main.lastInboxPull = "";
      }


      await axios.get('.netlify/functions/List', {
          headers: {
              'sessionGuID': main.sessionGuid,
              'sessionTxID': main.sessionTxID,
              'walletID': main.addr,
              'view': "inbox",
              'Content-Type': 'application/json',
            },
            params: {
              requestTime: main.lastInboxPull,
              loadAll: getAll,
            }
          })
      .then(async response =>  {
          main.AddMessages(main, response.data)

//        main.lastInboxPull = Date.now();

          if (window.localStorage ) {
//            localStorage.setItem("lastInboxPull", main.lastInboxPull)
            localStorage.setItem("inboxMessages", JSON.stringify(main.Messages))
 //           localStorage.setItem("inboxTracking", JSON.stringify(main.Tracking))
          }

          this.connected = false;
          this.busy = false;

        })
      .catch(function(){
        if(!count) count = 0
        count ++

        if(count<=3)
          setTimeout(main.GetInbox, 1000, main, count );
      })

      main.connected = false;
      main.busy = false

    },
    AddMessages(main, messages){
      main.Messages = messages;
    },
    GetUnread(main){
      this.unread = 0;
      for(var i=0; i<main.Messages.length; i++){
        var item = main.Messages[i]
        if(item.assetID in this.Tracking &&
         this.Tracking[item.assetID].r == 0){
          this.unread++
         }

      }
      main.SetTitle(main);
    },
    SetTitle(main){
      var badge = "";
      if(main.unread != 0) badge = "("+ main.unread +")"
      
      document.title = "Engagr " + badge
    },
    ResetTitle(){
      document.title = "Engagr"
    },

    MessageSelected(evt){
      this.openMessage = evt;

      this.startRead = new Date();

      this.send_address = evt.t;
      this.to = evt.t;
      this.from = evt.s;
      this.subject = evt.sl;

      this.id = evt.id;
      this.send_date = evt.sd;

      if(this.addr == this.to)
        this.buddyID = this.from;
      else{
        this.buddyID = this.to;
      }
      
      this.getHero();

      this.previewMessage = true

      this.GetUnread(this);
    },
    async OptedIn(assetID){
      var headers = {
              'sessionGuID': this.sessionGuid,
              'sessionTxID': this.sessionTxID,
              'walletID': this.addr,
              'view': "isoptedin"
          }

      var message_payload ={
        messageID: assetID
      }

      return await axios.post(".netlify/functions/Config",message_payload, { headers: headers} )
          .then(async function (response) {
            console.log(response.data)
            return response.data.round != 0
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });

    },
    async OptingIn(assetID){
      console.log("OPTING IN MOFO")
      var headers = {
              'sessionGuID': this.sessionGuid,
              'sessionTxID': this.sessionTxID,
              'walletID': this.addr,
              'view': "optin"
          }

      var message_payload ={
        messageID: assetID
      }

      axios.post(".netlify/functions/Config",message_payload, { headers: headers} )
          .then(async function (response) {
            console.log(response)
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async PromoShowed(message){
      console.log(message)

      if( await this.OptedIn(message.assetID) == false ){
        await this.OptingIn(message.assetID)
      }

      var headers = {
              'sessionGuID': this.sessionGuid,
              'sessionTxID': this.sessionTxID,
              'walletID': this.addr
          }

      var message_payload = {
              "u": message.sender,
              "d": {
                "t":message.messageDeets.t,
                "i":message.assetID
              },
              "t": "read"
            }
      var t = this
      
      console.log(message)

      if( this.visibleView == "inbox"){
        axios.post(".netlify/functions/LogAction",message_payload, { headers: headers} )
          .then(async function (response) {
            console.log("LOGGEDREAD: " + response.data)

            var threadObj = {addr:t.addr, threadBuddy:t.buddyID, messageID: message.assetID}
            await t.$store.dispatch("updateMessage", threadObj)
            
            if(response.data.substr(0,4) == "PAID"){
              t.ShowNotification(response.data, "green")
            }
            else if(response.data != ""){
              t.ShowNotification(response.data, "red")
            }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    },
    PromoClosed(message, rt){
      var seconds = Math.floor(rt/1000)
        if(seconds > 30) seconds = 30;


        var headers = {
              'sessionGuID': this.sessionGuid,
              'sessionTxID': this.sessionTxID,
              'walletID': this.addr
          }

      var assetID = message.assetID

      var message_payload = {
              "u": this.addr,
              "d": {
                "i":assetID,
                "rt":seconds
              },
              "t": "readTime"
            }


      if( this.visibleView == "inbox"){
        axios.post(".netlify/functions/LogAction",message_payload, { headers: headers} )
          .then(async function (response) {
            console.log("LOGGEDREATIME: " + response.data)
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    },
    ShowNotification(message, color){
      this.noticeColor = color
      this.noticeText = message;
      this.notice = true;
    },

    showCreate(){
      console.log("Show it.")
      this.to = "";
      this.from = this.addr;
      this.subject = "";
      this.content = "";
      this.asaid = "";
      this.callToAction = "";
      this.asaColor = ""
      this.compose = true;
    },
    Navigate(to){
      this.visibleView = to;

      switch(to){
        case "inbox":

          this.inbox = true;
          this.outbox = false;
          this.rewards = false;
          this.payments = false;
          break;
/*
        case "sent":
        if(this.OutboxMessages.length == 0)
          {
            this.ListOutboxMessages();
          }

          this.inbox = false;
          this.outbox = true;
          this.rewards = false;
          this.payments = false;
          break;
*/
        case "rewards":
          //this.ListRewards();
          this.rewards = true;
          this.payments = false;
          this.inbox = false;
          this.outbox = false;
          break;

        case "payments":
          //this.ListPayments();
          this.rewards = false;
          this.payments = true;
          this.inbox = false;
          this.outbox = false;
          break;

      }
      this.drawer = false;
    },
    ShowForceNotification(val){
      this.reminders = val
    },
    SendNotification(data){
      console.log("Sending Notification")
      var content = "You received a new message. It has a " + data.x + " Algo reward for just clicking."

      var headers = {
        "Content-Type": "application/json; charset=utf-8",
        "accept":"application/json",
        "Authorization": "Basic NGFkNDYwZDgtMzRhMi00ZThlLThmYjItYjc5NzZjNjVmOTM4"
      };
      var message = { 
        app_id: "57fdccb0-5614-4113-ba35-edf7dcce1111",
        contents: {"en": content},
        include_external_user_ids: [data.t]
        
      };

      var url = "https://onesignal.com/api/v1/notifications"
      axios.post(url,message, { headers: headers})
      .then(function (response) {
          console.log(response.data)
      })
    }  
  }


};
//          


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 36px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #17538f;
}

.red{
  color:red;
}
.green{
  color:green;
}

.icon-spinner {
    animation: spin-animation 1s infinite;
    display: inline-block;
}

@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.button-holder{
  margin-top:20px;
  margin-bottom:10px;
}

.ProseMirror {
    padding: 8px 18px;
    min-height: 98px !important;
    overflow-wrap: anywhere;
  }

  .borderless {
  border-style: none !important;
}
.v-main{
  padding-top:56px !important;
}
.onesignal-customlink-subscribe{
  width:68px !important;
  font-weight:bold !important;
}
</style>

