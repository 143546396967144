<template>
  <div>
    <v-sheet color="black">
          <v-avatar><AvatarView :sender="addr" label="false"></AvatarView></v-avatar>
          <v-list-item-title   class="white--text">
            <AddressOnly :addr="addr"></AddressOnly>
          </v-list-item-title>
    </v-sheet>

      <v-divider></v-divider>

      <v-list >
        <v-list-item-group
          v-model="selectedItem"
          color="purple">
          <v-list-item @click="OpenPro()">
            <v-list-item-icon>
                <v-icon>mdi-email</v-icon>
            </v-list-item-icon>  
            <v-list-item-content class="subtitle-2">Inbox</v-list-item-content>
          </v-list-item>
<!--
          <v-list-item @click="OpenSent()">
            <v-list-item-icon>
                <v-icon>mdi-email-send-outline</v-icon>
            </v-list-item-icon>  
            <v-list-item-content class="subtitle-2" >Sent Items</v-list-item-content>
          </v-list-item>
        -->
          <v-list-item @click="OpenRewards()">
            <v-list-item-icon>
                <v-icon>mdi-account-plus</v-icon>
            </v-list-item-icon>  
            <v-list-item-content class="subtitle-2" >My Rewards</v-list-item-content>
          </v-list-item>

          <v-list-item @click="OpenReminders()">
            <v-list-item-icon>
              <v-icon>mdi-bell</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="subtitle-2" >Settings</v-list-item-content>
          </v-list-item>


          <!-- v-list-item @click="TestMethod()">
            <v-list-item-icon>
              <v-icon>mdi-bell</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="subtitle-2" >Test</v-list-item-content>
          </v-list-item -->

        </v-list-item-group>
      </v-list>
    </div>
</template>

<script>
import AddressOnly from "./Address-Only.vue";
import AvatarView from "./Avatar-View.vue";
import axios from "axios";


export default {
  components:{
    AddressOnly,
    AvatarView,
  },
  props:["addr", "unread"],
  data(){
    return {
      selectedItem: 0
    }
  },
  methods:{
    GetProfile(){
      if(this.addr == null){
        return null;
      }
      let profile = this.$store.getters.getProfile(this.addr)

      if(profile){
        return profile
      }

      return null;
    },
    ShowAvatar(){
      let prof = this.GetProfile(this.addr)

      return (prof != null);
    },
    GetAvatar(){

      let prof = this.GetProfile(this.addr)
      if( prof != null){
        return prof.properties.userDefined.avatar;
      }
      else{
        return "";
      }
    },
    GetName(){

      let prof = this.GetProfile(this.addr)
      if( prof != null){
        return prof.name;
      }
      else{
        return "loading";
      }
    },
    OpenSent(){
      console.log("OpenSent")
      this.$emit("Navigate", "sent")
    },
    OpenPro(){
      console.log("OpenPro")
      this.$emit("Navigate", "inbox")
    },    
    OpenPayments(){
      console.log("OpenPayments")
      this.$emit("Navigate", "payments")
    },    
    OpenRewards(){
      console.log("OpenRewards")
      this.$emit("Navigate", "rewards")
    },   
    OpenReminders(){
      console.log("OpenReminders")
      this.$emit("ForceShow", true)
    },   
    UnreadCount(){
      if(this.unread ==0 ) return "";
      else return "("+ this.unread +")"
    },
    TestMethod(){
      axios.post( ".netlify/functions/Notifications",{}, { } )
            .then(async function (response) {
              console.log(response)
            })
    } 

  }

}
</script>