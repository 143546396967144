<template>
        <v-card height="90vh" id="ScrollCard" ref="ScrollCard" class="ScrollCard">
          <v-app-bar app>

            
            <v-app-bar-title>              
              
              <v-icon x-large @click="closeWindow">mdi-menu-left</v-icon>
              <AddressOnly :addr="buddyID" maxLength="18"></AddressOnly>   
                      <v-menu
                    bottom
                    center
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="red lighten-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon title="report account">mdi-alarm-light</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="(item, index) in reportAbuse"
                        :key="index"
                        @click="sendAbuseReport(item)" >

                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>

                  </v-menu>
              
              
            </v-app-bar-title>
          </v-app-bar>
          
          <v-card-text>
            <v-container fluid  id="FullWidthDeal" ref="FullWidthDeal">
              <v-row align="end" id="ScrollCardText" ref="ScrollCardText" class="ScrollCardText">
                <v-col >

              <MessageView
                location="showAll"
                :addr="addr"
                :buddyID="buddyID"
                :sessionGuid="sessionGuid"
                :sessionTxID="sessionTxID"
                :selectedMessage="selectedMessage"
                :messageID="messageID"
                :visibleView="visibleView"
                :previewMessage="previewMessage"

                showAll="true"
                displayAll="true"
                :renderCount="renderCount"
                @PromoShowed="ShowPromo"
                @PromoClosed="PromoClosed"
              ></MessageView>


                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

            <v-card-actions class="pa-0 ma-0">
              <MessageForm
                FormPurpose=""
                :addr="addr"
                :sessionGuid="sessionGuid"
                :sessionTxID="sessionTxID"
                
                :to="buddyID"
                @send="send"
                @closeWindow="closeWindow"
               ></MessageForm>
              </v-card-actions>

           

          
      

        </v-card>

</template>

<script>
import AddressOnly from "./Address-Only.vue";
import MessageView from "./Message-View.vue";
import MessageForm from "./Message-Form.vue";
import * as moment from 'moment'

import axios from "axios";
//import MessageView from "./components/Message-View.vue";


export default {
  components: {
    AddressOnly,
    MessageView,
    MessageForm,
  },
  props: ["addr","buddyID","sessionGuid","sessionTxID","location", "messageID","selectedMessage","visibleView","renderCount","previewMessage" ],
  data: () => {
    return {
      reply: true,
      drawer: true,
      contentCount: 0,
      reportAbuse:[
        {title:"Aggressive or abusive content", value:"abuse"},
        {title:"Misrepresented themself", value:"phish"},
        {title:"Inappropriate content", value:"innap"},
        {title:"Too many messages", value:"tooma"},
        {title:"Other", value:"other"}
      ],
    }
  },

  mounted(){
    console.log("LOADS")
    console.log(window.innerHeight )
    var hy  = window.innerHeight * .9
    this.$refs.ScrollCardText.style.height = (hy-175) + "px";
  },  
  methods: {
    
    LoadBits(){
    
    },
    GetProfile(  ){
    
    },
    async GetDomain(){
    },
    FormatDate: function (val) {
      return moment.unix(val).fromNow()
    },
    sendAbuseReport(item){
      console.log(item)

      var headers = {
              'sessionGuID': this.sessionGuid,
              'sessionTxID': this.sessionTxID,
              'walletID': this.addr
          }

      var message_payload = {
              "s": this.from,
              "r": this.to,
              "i": this.id,
              "rt": item.value
            }


        axios.post(".netlify/functions/ReportSender",message_payload, { headers: headers} )
              .then(async function (response) {
                console.log(response)
            })
            .catch(function (error) {
              console.log(error);
            });
    },
    async Reply(){
      console.log("Reploy: " + this.to)
      //this.previewMessage = false;
      this.reply = true;
      location.hash = "#ReplySpace" ;


      var me = this
//      var old_to = this.to;
//      this.to = this.from;

      
      await axios.get("https://api.nf.domains/nfd/address?address=" + this.to ).then(resp => {
        if(resp.data.length>0){
          
          console.log("Returned Value:" + resp.data[0].name)
          
          //order matters here.
          me.send_address = this.to
          me.to = resp.data[0].name

        } else {
          me.to = me.send_address;
        }
      });

      this.from = this.addr;
      this.accountResults = "valid"
      
      
      this.AccountCheck = "mdi-checkbox-marked"

//      var to = this.from;
      this.subject = "RE:" + this.subject;
      this.asaid = "";
      this.content = "";
      this.callToAction = "";
      this.accountColor = "";
      this.AccountCheck = "";

      //this.dialog = true;
    },
    send(mesg){
      console.log("HOLDER SEND")
      this.$emit("send", mesg)
    },
    closeWindow(){
      console.log("CLOSING THIS THING")
      this.$emit("closeWindow");
    },
    ClickCall(){
      //if(this.to != this.addr){
        var headers = {
              'sessionGuID': this.sessionGuid,
              'sessionTxID': this.sessionTxID,
              'walletID': this.addr
          }

        var message_payload = {
              "u": this.addr,
              "d": {
                "i":this.selectedMessage.messageDeets.c
              },
              "t": "click"
            }
        if( this.visibleView == "inbox"){
          axios.post(".netlify/functions/LogAction",message_payload, { headers: headers} )
          .then(async function (response) {
            console.log("LOGGEDCLICK: " + response.data)
          })
          .catch(function (error) {
                console.log(error);
          });
          window.open(this.selectedMessage.messageDeets.c )
        }
    },
    ShowPromo(m){
      this.$emit("PromoShowed", m)
    },
    PromoClosed(m, rt){
        this.$emit("PromoClosed", m, rt)
    }

  }
};
</script>

<style>
html {
  overflow: hidden !important;
}
/*
.ScrollCard {
  display: flex !important;
  flex-direction: column;
}

.ScrollCardText {
  flex-grow: 1;
  overflow: auto;
}
*/
.NavDrawer{
  transform: translateX(0) !important; 
  visibility:visible !important;
 /* left:0px; */
}

#FullWidthDeal {
  width:100%;
  margin-left:10px !important;
  margin-right:10px !important;
}

</style>