<template>
  <div>
      <v-app-bar
        color="purple lighten-1"
        dark
      >
        <v-toolbar-title><v-icon color="white">mdi-email-outline</v-icon> {{MessageListTitle}} - <span class="text-body-1">Showing last 14 days</span></v-toolbar-title>
        <v-spacer></v-spacer>

        <v-divider
          vertical
        ></v-divider>
      </v-app-bar>
    


      <v-data-table
        :headers="headers"
        :items="Messages"
        :loading="isLoading"
        sort-by="sd"
        :sort-desc="sortDesc"
        item-key="id"
        class="elevation-1"
        @click:row="ClickRow"
        hide-default-footer
        height="83vh"
        disable-pagination
      >
        <template v-slot:[`item`]="{item}">
          <tr>
            <td colspan="4"  >

                  <table style="width:100%;">
                    <tr  @click="ClickRow(item)">
                    <td style="padding-left:10px;width:600px;">

                        <div class="text-left font-weight-bold text-h6">                  
                          <AvatarView :sender="item.b" label="true"></AvatarView>
                        </div>
                        <div class="text-left " style="margin-left:50px;">{{ item.sl }}</div>
                        <div class="text--caption text-left text-italics" style="margin-left:50px;">{{ FormatDate(item) }}</div>
                    </td>                  
                    <td class="text-right"  align="right" style="width:60px;">
                      <div>{{ GetCount(item) }} {{ GetCurrency(item) }} </div>
                    </td>
                    </tr>
                  </table>
            </td>
          </tr>
        </template>
      </v-data-table>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import * as moment from 'moment'
import axios from "axios";
import AvatarView from "./Avatar-View.vue";
import AddressOnly from "./Address-Only.vue";

export default {
  props:["Messages","ForceRefresh","MessageListTitle","Tracking","addr"],
  components:{
    AvatarView,
    AddressOnly,
  },
  data(){
    return {
      isLoading: false,
      sortDesc: true,
      groupDesc: true,
      domainsVisible:{},
      
      headers: [
      {
            text: 'From',
            align: 'start',
            value: 's',
            groupable: true,
            width: '275px',
            class: 'purple lighten-4',
          },
          { text: '', value: 'sl', align: 'left', groupable: false,class: 'purple lighten-4'},
          { text: 'Date', value: 'sd', align: 'left', groupable: false, width:"175px", class: 'purple lighten-4'},
          { text: 'Reward', width:'150px',value: 'b', align: 'right', groupable:false,class: 'purple lighten-4' },
      ],
    }
  },
  watch:{
    isLoading(newValue, oldValue){
      if(newValue == false){
        console.log("Done loading" + oldValue)
      } else {
        console.log("Not done")
      }
    },
  },
  methods: {
    /*
    ShowCreate(){
      console.log("Sending the love")
      this.$emit('showCreate')
    },
    
    Grouped(){
      this.groups = "sender";
    },
    NotGrouped(){
      this.groups = [];
    },
    Refresh(){
      this.$emit("Refresh");
    },
    async LoadAvatars(messages){
      console.log("Loading Avatars: " + this.Messages.length)
      for(var m in this.Messages)
      {
        let id = this.Messages[m].sender;
        await this.$store.dispatch('fetchProfile', { walletID: id })
      }
      //this.$emit("MessagesLoaded");
    },
    IsBold(item){

      try{
        if(item.assetID in this.Tracking &&
         this.Tracking[item.assetID].r == 0)
      {
        return "font-weight-bold"
      } else if(item.assetID in this.Tracking &&
                this.Tracking[item.assetID].r > 0){
        return "font-weight-regular"
      } else {
        return "grey--text"
      }
      }
      catch(ex){
        return "grey--text"
      }
      
     return "grey--text"
     },
     
    TrimWallet: function (val) {
      let walletID = "" + val.sender
      
      return walletID.substring(0,2)
    },


    TrimContent: function (val) {

      let walletID = "" + val.messageDeets.b
      walletID = walletID.replace(/<\/?[^>]+(>|$)/g, "");

      return walletID.substring(0,90)
    },
    */
    ClickRow: function ($event) {
      this.$emit("MessageSelected",  $event);
    },
    FormatDate: function (val) {
      return moment(val.sd).fromNow()
    },
    /*
    CountReward: function (item) {
      var total =  0;
      for(var i=0;i<item.length; i++){
        total += item[i].messageDeets.x
      }
      return item.messageDeets.x;
    },
    
    IsVisible(item){
      var isLogged = item.sender in this.domainsVisible;
      
      if( isLogged)
      {
        return "HideRow"
      } else {
        //this.domainsVisible.push(item.sender)
        return "ShowRow";
      }
    },
    
    filterOnlyFirst (value, query, item) {
      var wallet = "";
      
      if(this.addr == item.sender)
        wallet = item.messageDeets.t;

      var isLogged = wallet in this.domainsVisible;

      if( isLogged)
      {
        this.domainsVisible[wallet].push(item)
        return false
      } else {
        this.domainsVisible[wallet] = []
        this.domainsVisible[wallet].push(item)
        return true;
      }
    },
    */
    GetCurrency(item){
      if(!item.asaName) return "Algo"
      else return item.asaName
    },
    GetCount(item){
      /*
      var list = this.domainsVisible[item.sender];
      var count = 0;

      for(var i=0;i<list.length; i++){
        count += list[i].messageDeets.x
      }

      return count
      */
     return item.rw;
    },
    /*
    MessageCount(item){

     return 1;
    },
    GetUnread(item){
      var list = this.domainsVisible[item.sender];
      var unread = 0;
      
      for(var i=0; i<list.length; i++){
        try{
          if(list[i].assetID in this.Tracking &&
          this.Tracking[list[i].assetID].r == 0)
          {
            unread++;
          } 
        } catch(ex){
          console.log(ex)
        }
      }
      return unread;
    
      return 0;
    } */
  }
};
</script>
<style>
thead.v-data-table-header th[role=columnheader] {
  font-size: 14px !important;
}
thead.v-data-table-header tD {
  font-size: 14px !important;
}

.v-data-table{ 
  overflow:auto !important;
  height:100%;
}
</style>

