<template>
          <v-card>
      <v-toolbar
          dark
          color="grey darken-2"
        >
          <v-btn
            icon
            dark
            @click="closeFTUX"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>First Time <img height="18" src="../../src/assets/engagr-sm.png"/>-ing?</v-toolbar-title>
          
        </v-toolbar>

        
        <v-card-title class="text-h5 mb-1">What is Engagr?</v-card-title>
        <v-card-subtitle class="text-sm-left">
            A crypto-based messaging service where message recipients gets paid.
        </v-card-subtitle>

        <v-card-text>
            <v-card>
 
                <v-tabs
                    align-with-title
                    color="black"            
                    background-color="blue lighten-4"
                    grow
                    v-model="selectedTab"
                >
                <v-tabs-slider color="yellow"></v-tabs-slider>
                <v-tab
                    v-for="item in items"
                    :key="item.title"
                >
                    {{ item.title }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="selectedTab">
                    <v-tab-item
                        v-for="item in items"
                        :key="item.title"
                    >
                        <div style="font-weight:bold; font-size:18px; text-align:left; padding-left:20px;padding-right:20px; margin-top:20px;">{{ item.title }}</div>
                        <div style="font-size: 12px; text-align:left; padding-left:20px;padding-right:20px;">{{ item.desc }}</div>
                        <div style="text-align:left; padding-top:20px; padding-left:20px;"><img :src="item.img" width="50%"/></div>
                    </v-tab-item>
                </v-tabs-items>
 
            </v-card>
        </v-card-text>
      </v-card>
</template>
<script>
export default {
  props: ["addr", "showWallet"],
  data: () => {
    return {
        selectedTab: null,
        items: [
            {
                title:"View your Inbox",
                desc: "View the messages that have been sent to you. Not every message has a reward, but some of them do. To compose a message, click/touch the 'compose' button in the top right corner of the inbox.",
                img: require("../../src/assets/list.png")
            },
            {
                title:"Get Paid",
                desc: "Do you like money? Everyone does, every time you have a package show up on a message, you have crypto waiting for you just by clicking the gift icon. Once a gift has been distributed, the gift icon, shows as opened.",
                img: require("../../src/assets/paid.png")
            },

        ]

    }
  },
  methods: {
    closeFTUX(){
        console.log("CHILD CLOSE")
        this.$emit("closeFTUX", null)
    }
  }
};
</script>

