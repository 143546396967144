<template>
    <v-form style="padding:0px; width:100%;">
        <v-card width="100%" flat outlined color="grey lighten-3">
        <v-card-title v-if="FormPurpose!=''">
          <v-app-bar flat color="#000000" >

            <v-toolbar-title class="text-h6 white--text pl-0">
              <v-icon dark>mdi-file-edit-outline</v-icon>
              {{FormPurpose}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon dark @click="closeWindow">mdi-close</v-icon>
          </v-app-bar>
        </v-card-title>
        <v-card-text>
          <v-container no-gutters dense >
            <v-row v-if="FormPurpose!=''">
              <v-col cols="12">
                <v-text-field
                  dense
                  label="To:"
                  v-model="localTo"   
                  :append-icon="accountCheck"                                                                                                                                
                  @input="getAccount"
                  required
                  :error-messages="toError"
                ></v-text-field>
                <div :class="accountColor">{{accountResults}}</div>

              </v-col>
            </v-row>

            
            <v-row dense>
              
              <v-col
                cols="12"
              >
              <v-text-field
                  dense
                  label="Enter your message here"
                  v-model="localSubject"
                ></v-text-field>
                <div style="color:red">{{subjectResults}}</div>

              </v-col>

              <!-- v-col cols="2">
                <v-text-field
                  dense
                  label="Algo Reward"
                  v-model="localBounty"
                  type="number"
                ></v-text-field>
              </v-col -->
              <v-col cols="2" v-if="HasReward()==false">          
                <v-btn
                  color="blue lighten-3"
                  @click="send"
                >
                  Send
                </v-btn>
              </v-col>
            </v-row>
            
            <v-row dense  v-if="HasReward()">
              <v-col cols="12">
                <v-checkbox
                  v-model="showAsa"
                  label="Hero NFT"
                ></v-checkbox>


                <v-img
                  width="600"

                  :src="localHero_url"
                  v-if="showAsa"
                >
                      <v-text-field
                        label="Enter ASA ID Here"
                        background-color="rgba(0, 0, 0, 0)"
                        
                        
                        solo
                        flat
                        v-model="localAsaID"
                        @input="getHero"
                        class="borderless"
                      ></v-text-field>

              </v-img>
              <div style="color:red">{{asaResults}}</div>

              </v-col>
            </v-row>
        

            <v-row dense  v-if="HasReward()">
              <v-col cols="12">
                <!--TipTap :content="content"/ -->
                <!-- v-tiptap
                    v-model="localContent"
                    @keydown.native="onKeydown"
                    height="140px"
                    :toolbar="['h1', 'p', '|', 'bold', 'italic', 'underline', '|', 'color','|','left','center','right']"
                  ></v-tiptap-->
                  <v-textarea
                    name="input-7-1"
                    v-model="localContent"
                    @keydown.native="onKeydown"
                    
                    label="Your message"
                    hint="Enter Markdown for more dynamic content"
                  ></v-textarea>
                <div style="width:100%; height:14px;">{{contentCount}}/500</div>
                <div style="color:red">{{contentResults}}</div>

              </v-col>
              </v-row>

            <v-row dense  v-if="HasReward()">
              <v-col cols="12">
                <v-checkbox
                  v-model="showCTA"
                  label="Button with CTA"
                ></v-checkbox>

                <v-text-field
                  dense
                  label="Call to action (URL)"
                  v-model="localCallToAction"
                  v-if="showCTA"
                ></v-text-field>
                <div style="color:red">{{ctaResults}}</div>

              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions v-if="HasReward()">
          <v-spacer></v-spacer>

          <!-- v-btn
            color="blue darken-1"
            text
            @click="preview"
          >
            Preview
          </v-btn -->

          <v-btn
            color="blue ligthen-3"
            @click="send"
          >
            Send
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-form>
</template>

<script>
import axios from "axios";
//import { required } from 'vuelidate/lib/validators'

    export default {
    props: ["FormPurpose","addr","sessionGuid","sessionTxID","to","subject"],
    data: () => {
        return {
            showAsa: false,
            showCTA: false,

            localAsaID: "",
            localContent: "",
            localSubject:"",
            localCallToAction:"",
            localTo:"",
            localHero_url:require("../../src/assets/engagr-pickr.png"),
            localBounty: 0,
            accountResults: "",
            subjectResults: "",
            asaResults: "",

            contentResults: "",
            contentCount: 0,

            ctaResults: "",
            accountCheck: "",
            accountColor:"",
            send_address: "",
            toError: "",
        
        }
    },
    mounted(){
        console.log("To:  " + this.to);

        if(this.to != ""){
          console.log("MOUNTED THING: "+ this.to)
            this.localTo = this.to;
            this.send_address = this.to;
            //this.localSubject = "RE:" + this.subject;
            //this.GetDomain(this.to)
        }
    },
    methods: {
        async getAccount(){
            console.log("getAccount")

            //if the to field is empty
            if(this.localTo == ""){
                console.log("no account selected")
                this.send_address = ""
                this.accountResults = "Please enter a recipient"
                this.accountColor = "redText"
                this.validated_account = false;
                
                return false;
            } 


            if(!this.checkDomain(this.localTo)){
                if(!this.checkAccount(this.localTo))
                  console.log("NO ACCOUNT")
                    return false
            }

            this.GetDomain(this.localTo);
            return true
        },
        async checkDomain(account_id){
            try{
                var url = "https://api.nf.domains/nfd/" + account_id.toLowerCase();
                //get the Domain
                axios.get(url).then(resp => {
                    //yes there is a domain.
                    
                    this.send_address = resp.data.depositAccount
                    this.accountResults = resp.message;
                    this.accountResults = ""
                    this.accountCheck = "mdi-checkbox-marked"
                    this.accountColor = "greenText"
                    
                    return true;
                })
                .catch((err) => {
                  if(account_id.length == 58 ){
                    return this.checkAccount(account_id)                    
                  }


                    //nope, use fall back condition
                    this.accountResults = err.response.data.message;
                    this.AccountCheck = ""
                    this.accountColor = "redText"

                })
            }
            catch(ex){
                console.log(ex)
            }

        },
 
        async checkAccount(account_id){
            let url = "https://mainnet-idx.algonode.network/v2/accounts/" + account_id
        
            console.log("checkAccount:" + url)

            try{
                axios.get(url ).then(resp => {
                console.log(resp.data.account.address)
                this.send_address = resp.data.account.address
                //this.accountResults = "valid"
                this.accountCheck = "mdi-checkbox-marked"
                this.accountColor = "greenText"
                this.accountResults = ""

                //this.accountColor = "green"
                
                return true;
                })
                .catch((ex) => {
                    console.log(ex)
                })
                .finally(() => {          
                });
            }
            catch(ex){
                console.log(ex)
            }
            this.accountColor = "redText"
            return false;
        },
        async GetDomain(addr){
          console.log("GetDomain: " + addr)
            var url = "https://api.nf.domains/nfd/"+ addr 
            var me = this;
            await axios.get(url).then((response) => {  
                console.log("DOMAIN IS GOOD")          
                console.log(response.data)
                me.send_address = response.data.owner

            })
            .catch(async function(){
                var url = "https://api.nf.domains/nfd/address?address="+ addr +"&view=thumbnail&limit=1"

                //me.localTo =addr;
                me.send_address = addr;

                await axios.get(url).then((response) => {  
                    console.log(response.data)
                    console.log("Valide wallet")
                    //me.localTo = response.data[0].name
                });
            });
            
        },
        getHero(){
            console.log("GETTING HERO")

            try{
                var me = this;

                var message_payload = {
                    "asaid": parseInt(this.localAsaID)
                }

                var headers = {
                    'sessionGuID': this.sessionGuid,
                    'sessionTxID': this.sessionTxID,
                    'walletID': this.addr,
                    'view': "nftsrc"
                }

                axios.post( ".netlify/functions/Message",message_payload, { headers: headers} )
                .then(async function (response) {
                    console.log(response.data)
                    var suffix = response.data.substr(7)
                    me.localHero_url = "https://ipfs.algonode.xyz/ipfs/" + suffix;
                    me.asaResults = ""
                })
                .catch(function(){
                    this.asaResults = "Please enter a valid ASA ID"
                })
            }
            catch(exception){
                console.log(exception)
                this.validated_id = false;
                this.asaResults = "Invalid ASA ID"  
                this.NFTCheck = ""
                //this.hero_height=100
                this.localHero_url = require("../../src/assets/engagr-pickr.png")
            }
        }, 
        closeWindow(){
            this.$emit("close");
        },
        preview(){

        },
        validate(){
            var success = true;

            //clear the mechanism
            this.accountResults = ""
            this.subjectResults = ""
            this.asaResults = ""
            this.contentResults = ""
            this.ctaResults = ""

            if(!this.getAccount()){
                if(this.accountResults == ""){
                    this.accountResults = "Please enter a recipient"
                    this.accountColor = "redText"
                }
                success = false;
            }

            if(this.localSubject == ""){
                this.subjectResults = "Please enter a message"
                success = false;
            }
            if(this.showAsa == true && this.asaResults != ""){
                success = false;
                this.asaResults = "Your ASA is invalid"
            }

            if( this.localBounty > 0 && this.localContent == ""){
                success = false
                this.contentResults = "Please enter content for your message"
            }

            if(this.showCTA == true && this.localCallToAction == ""){
                success = false
                this.ctaResults = "Please enter a URL"
            }

            return success;
        }, 
        send(){
            if(!this.validate()){
              console.log("Did not vslidate")
                return;
            }
            var send = {
                "s": this.localSubject,
                "b": this.localContent,
                "t": this.send_address,
                "m": "cta",
                "c": this.localCallToAction,
                "a": this.localAsaID,
                "x": parseFloat(this.localBounty),
                "i": null,
                }
            console.log("Form SEND")
            this.$emit("send", send);
            this.$emit("closeWindow");
        },
        onKeydown(event){
          this.contentCount = this.localContent.length
          if(this.localContent.length > 500)
          {
            event.preventDefault();
          }
        },
        HasReward(){
          if(this.localBounty > 0){
            return true
          } else {
            return false;
          }
        }
      }
    };
</script>

<style>
.redText{
  color:red;
}
.greenText{
  color:green;
}

</style>
